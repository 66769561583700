import React, {useEffect, useState} from 'react';
import "./WebAppsTeaser.scss";
import {animated, config, useSpring} from "react-spring";
import smartphone from "../../../assets/icons/noun_Smartphone_409470.svg";
import pwa from "../../../assets/icons/noun_Apps_914827.svg";
import desktop from "../../../assets/icons/noun_desktop_1565605.svg";
import {IonButton, IonLabel} from '@ionic/react';
import {faChevronDoubleDown} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useWindowResize} from "../../windowResize";

export const WebAppsTeaser: React.FC = () => {

    // https://www.react-spring.io/docs/hooks/api
    const fadeIn = useSpring({
        opacity: 1,
        from: {
            opacity: 0,
        },
        delay: 500,
        config: config.gentle
    })

    const iconIOS = useSpring({
        opacity: 0,
        right: 220,
        transform: "rotate(-15deg) scale(0.5)",
        from: {
            opacity: 1,
            right: 0,
            transform: "rotate(-3deg) scale(1)",
            top: 50
        },
        delay: 1750,
        config: config.gentle
    })

    const labelIOS = useSpring({
        opacity: 0,
        right: 220,
        transform: "rotate(-15deg) scale(0.5)",
        from: {
            opacity: 1,
            right: 0,
            transform: "rotate(-3deg) scale(1)",
            top: 170
        },
        delay: 1750,
        config: config.gentle
    })

    const iconAndroid = useSpring({
        opacity: 0,
        right: 220,
        transform: "rotate(-15deg) scale(0.5)",
        from: {
            opacity: 1,
            right: 0,
            transform: "rotate(10deg) scale(1)",
            top: 250
        },
        delay: 2100,
        config: config.gentle
    })

    const labelAndroid = useSpring({
        opacity: 0,
        right: 220,
        top: 150,
        transform: "rotate(-15deg) scale(0.5)",
        from: {
            opacity: 1,
            right: 0,
            transform: "rotate(10deg) scale(1)",
            top: 370
        },
        delay: 2100,
        config: config.gentle
    })

    const desktopProps = useSpring({
        opacity: 0,
        right: 220,
        transform: "rotate(0deg) scale(0.5)",
        from: {
            opacity: 1,
            right: 500,
            top: 150,
            transform: "rotate(-5deg) scale(1)"
        },
        delay: 1500,
        config: config.gentle
    })

    const labelDesktop = useSpring({
        opacity: 0,
        right: 220,
        transform: "rotate(0deg) scale(0.5)",
        from: {
            opacity: 1,
            right: 520,
            top: 320,
            transform: "rotate(-5deg) scale(1)"
        },
        delay: 1500,
        config: config.gentle
    })

    const pwaProps = useSpring({
        opacity: 1,
        transform: "rotate(5deg)  scale(1)",
        from: {
            opacity: 0,
            right: 130,
            transform: "rotate(0deg) scale(0.5)"
        },
        delay: 2500,
        config: config.wobbly
    })

    const labelPWA = useSpring({
        opacity: 1,
        transform: "rotate(5deg)  scale(1)",
        from: {
            opacity: 0,
            right: 210,
            transform: "rotate(0deg) scale(0.5)",
            top: 370,
        },
        delay: 2900,
        config: config.wobbly
    })

    const h1 = useSpring({
        opacity: 1,
        left: 0,
        from: {
            opacity: 0,
            left: -50,
        },
        delay: 2950,
        config: config.molasses
    })

    const h2 = useSpring({
        opacity: 1,
        left: 0,
        from: {
            opacity: 0,
            left: -50,
        },
        delay: 3050,
        config: config.molasses
    })

    const button = useSpring({
        opacity: 1,
        bottom: 0,
        from: {
            opacity: 0,
            bottom: -150,
        },
        delay: 3050,
        config: config.molasses
    })

    const size = useWindowResize();
    const [scale, setScale] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [height, setHeight] = useState(470);

    useEffect(() => {

        // Resize
        if (size !== undefined) {
            let scaleLocal = size.width! / 1200;

            if (scaleLocal > 1) scaleLocal = 1;
            setScale(scaleLocal);
        }
    }, [size])


    return (
        <div className="WebAppsTeaser" style={{
            height: height + "px"
        }}>

            <div className={"scene"} style={{
                transform: `scale(${scale})`
            }}>

                <animated.div style={fadeIn}>
                    <animated.img alt="" style={iconIOS} src={smartphone} className={"smartphone"}/>
                    <animated.div className={"label"} style={labelIOS}>iOS App</animated.div>

                    <animated.img alt="" style={iconAndroid} src={smartphone} className={"smartphoneAndroid"}/>
                    <animated.div className={"label"} style={labelAndroid}>Android App</animated.div>

                    <animated.img alt="" src={pwa} style={pwaProps} className={"pwa"}/>
                    <animated.div className={"label"} style={labelPWA}>WebApp</animated.div>

                    <animated.img alt="" src={desktop} style={desktopProps} className={"desktop"}/>
                    <animated.div className={"label"} style={labelDesktop}>Browser</animated.div>
                </animated.div>

                <animated.h1 style={h1}>Apps & Websites verschmelzen</animated.h1>
                <animated.h2 style={h2}>Eine Plattform, alle Betriebssysteme. Preiswert und effizient zur perfekten User Experience!</animated.h2>

                <animated.div style={button} className={"buttonWrapper"}>
                    <IonButton color={"light"} size={"default"}>
                        <FontAwesomeIcon icon={faChevronDoubleDown}/>
                        <IonLabel>Jetzt anschauen</IonLabel>
                    </IonButton>
                </animated.div>

            </div>
        </div>
    );
};
