import React, {useEffect, useState} from 'react';
import "./Quote.scss";

import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {loadMD} from "../../../data/helper";
import {Avatar} from '@material-ui/core';

export interface IQuote {
    mdFile: string;
    author?: string;
    avatar?: string
}

export const Quote: React.FC<IQuote> = ({
                                            author = "",
                                            mdFile = "",
                                            avatar = ""
                                        }) => {

    const [content, setContent] = useState("");
    useEffect(() => {
        // Load Teaser Text
        loadMD(mdFile).then((r) => {
            setContent(r)
        })
    }, [mdFile])

    return (

        <div className="Quote">
            <ReactMarkdown plugins={[[gfm]]} children={content}/>
            <div className={"avatarWrapper"}>
                <Avatar className={"quoteAvatar"}>
                    <img alt={author} src={avatar}/>
                </Avatar>
                <span>{author}</span>
            </div>
        </div>
    );
};

