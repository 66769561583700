import React, {useEffect, useState} from 'react';
import "./WhyBitsinmotion.scss";
import ReactMarkdown from "react-markdown";
import {loadMD} from "../../../data/helper";
import gfm from "remark-gfm";
import teaserTextW2P from "../../../cms/textWhyBitsinmotion.md";


export const WhyBitsinmotion: React.FC = () => {

    const [contentTeaser, setContentTeaser] = useState("");

    useEffect(() => {

        // Load Teaser Text
        loadMD(teaserTextW2P).then((r) => {
            setContentTeaser(r)
        })
    }, [])

    return (
        <div className="contentBox">

            <div className={"inner"}>
                <span>Warum bitsinmotion?</span>

                <ReactMarkdown plugins={[[gfm]]} children={contentTeaser}/>

            </div>
        </div>
    );
};
