import {IonPage} from '@ionic/react';
import React from 'react';
import {withRouter} from "react-router";
import Header from "../components/Header";
import {PageWebApps} from "./WebApps/PageWebApps";

const PageApps: React.FC = () => {

    return (
        <IonPage>

            <Header name={"apps"}/>

            <PageWebApps />

        </IonPage>
    );
};

export default withRouter(PageApps);

