export class AppHelper {
    public blindtext(count = 250) {

        let text = `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`;

        return text.substr(0, count);
    }
}


export const loadMD = async (path: string) => {
    return await fetch(path)
        .then(response => {
            return response.text()
        })
        .then(text => {
            return text
        })
}


export const createLink = (pageName: string = "", subpath: string = ""): string => {
    return `/${pageName}`;
}
