import React, {useEffect, useState} from 'react';
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {loadMD} from "../data/helper";

export interface IDisplayMD {
    input: string;
}

export const DisplayMD: React.FC<IDisplayMD> = ({
                                                    input
                                                }) => {

    const [content, setContent] = useState("");

    useEffect(() => {
        // Load Teaser Text
        loadMD(input).then((r) => {
            setContent(r)
        })
    }, [input])


    return (
        <div>
            <ReactMarkdown plugins={[[gfm]]} children={content}/>
        </div>
    );
};
