import {
  IonButtons,
  IonHeader,
  IonImg,
  IonLabel,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import "./Header.scss";

import bitsinmotion from "../assets/icons/bitsinmotion.svg";
import why from "../assets/icons/menu/why.svg";
import webapp from "../assets/icons/menu/webapp.svg";
import w2p from "../assets/icons/menu/w2p.svg";
import { RouteComponentProps, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/main";
import { getAppPage, setPage } from "../store/appStore";

interface ComponentProps extends RouteComponentProps<any> {
  name: string;
}

const Header: React.FC<ComponentProps> = (props: ComponentProps) => {
  const dispatch = useDispatch();

  let pageName = useSelector((state: RootState) =>
    getAppPage(state.appReducer)
  );

  useEffect(() => {




  }, [props.name, dispatch]);

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <div className="menuButton">
            <IonMenuButton />
          </div>
        </IonButtons>

        <div className="title">
          <IonImg className="bitsinmotion" src={bitsinmotion} />
        </div>
      </IonToolbar>

      <IonSegment
        className="menuSegment"
        value={props.name}
        onIonChange={(e) => {
          if (e.detail.value !== undefined) {

            console.log("!!",e.detail.value)

            window.location.href = `/${e.detail.value}`;

            // dispatch(setPage({ page: e.detail.value }));
          }
        }}
      >
        <IonSegmentButton value="">
          <div>
            <IonImg src={why} />
            <IonLabel>Warum bitsinmotion</IonLabel>
          </div>
        </IonSegmentButton>

        <IonSegmentButton value="webapps">
          <div>
            <IonImg src={webapp} />
            <IonLabel>Web Apps</IonLabel>
          </div>
        </IonSegmentButton>

        <IonSegmentButton value="webtoprint">
          <div>
            <IonImg src={w2p} />
            <IonLabel>web-2-print</IonLabel>
          </div>
        </IonSegmentButton>

        {/*<IonSegmentButton value="customers">*/}
        {/*    <div>*/}
        {/*        <IonImg src={customer}/>*/}
        {/*        <IonLabel>Referenzen</IonLabel>*/}
        {/*    </div>*/}
        {/*</IonSegmentButton>*/}

        {/*<IonSegmentButton value="demos">*/}
        {/*    <div>*/}
        {/*        <IonImg src={demos}/>*/}
        {/*        <IonLabel>Showroom</IonLabel>*/}
        {/*    </div>*/}
        {/*</IonSegmentButton>*/}

        {/*<IonSegmentButton value="about">*/}
        {/*    <div>*/}
        {/*        <IonImg src={about}/>*/}
        {/*        <IonLabel>Über uns</IonLabel>*/}
        {/*    </div>*/}
        {/*</IonSegmentButton>*/}
      </IonSegment>
    </IonHeader>
  );
};

export default withRouter(Header);
