import React from 'react';

export interface ISpacerProps {
    height?: number
}

export const Spacer: React.FC<ISpacerProps> = ({height}) => {

    return (
        <div className="Spacer" style={{
            height: height
        }}/>
    );

};

Spacer.defaultProps = {
    height: 30
};
