import {IonPage} from '@ionic/react';
import React from 'react';
import "./Page.scss";
import Header from "../../components/Header";
import {withRouter} from "react-router";
import {AppFooter} from "../../components/footer/AppFooter";
import {Imprint} from "../imprint";

const Page: React.FC = (props) => {

    return (
        <IonPage>
            <Header name={""}/>
              <Imprint />
            <AppFooter/>
        </IonPage>
    );
};

export default withRouter(Page);

