import React from 'react';
import "./WebAppBurger.scss";
import {animated, config, useSpring} from "react-spring";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSortUp} from "@fortawesome/pro-solid-svg-icons";

import native from "./noun_devices_217202.svg";
import pwa from "./noun_Apps_914827.svg"
import www from "./noun_pc web_453711.svg";

export const WebAppBurger: React.FC = () => {

    const lineY = useSpring({
        opacity: 1,
        height: "99%",
        bottom: 0,
        from: {
            opacity: 0,
            height: "3%",
            bottom: 0,
        },
        delay: 0,
        config: config.molasses
    })

    const lineX = useSpring({
        opacity: 1,
        width: "99%",
        left: 0,
        from: {
            opacity: 0,
            width: "3%",
            left: 0,
        },
        delay: 100,
        config: config.molasses
    })


    const lineLabels = useSpring({
        opacity: 1,
        from: {
            opacity: 0,
        },
        delay: 1000,
        config: config.gentle
    })

    const imgAnimWWW = useSpring({
        opacity: 1,
        bottom: 0,
        from: {
            opacity: 0,
            bottom: -100
        },
        delay: 1000,
        config: config.gentle
    })

    const imgAnimNative = useSpring({
        opacity: 1,
        left: 0,
        from: {
            opacity: 0,
            left: -100
        },
        delay: 1250,
        config: config.gentle
    })

    const imgAnimPwa = useSpring({
        opacity: 1,
        transform: "scale(1) rotate(12deg)",
        from: {
            opacity: 0,
            transform: "scale(0) rotate(0deg)"
        },
        delay: 1750,
        config: config.molasses
    })

    const divAnim = useSpring({
        opacity: 1,
        transform: "scale(1)",
        from: {
            opacity: 0,
            transform: "scale(0)",
        },
        delay: 500,
        config: config.molasses
    })

    return (
        <div className={"WebAppBurger"}>
            <div className={"inner"}>
                <div className={"lineY"}>
                    <animated.span style={lineY}>
                        <FontAwesomeIcon icon={faSortUp}/>
                    </animated.span>
                    <animated.label style={lineLabels}>Features</animated.label>
                </div>
                <div className={"a1"}>
                    <animated.div style={divAnim}>
                        <animated.img style={imgAnimNative} alt="native apps" src={native}/>
                        <label>Native App</label>
                    </animated.div>
                </div>
                <div className={"a2"}>
                    <animated.div style={divAnim}>
                        <animated.img style={imgAnimPwa} alt="pwa" src={pwa}/>
                        <label>PWA</label>
                    </animated.div>
                </div>

                <div className={"b1"}/>
                <div className={"b2"}>
                    <animated.div style={divAnim}>
                        <animated.img style={imgAnimWWW} alt={"websites www"} src={www}/>
                        <label>Websites</label>
                    </animated.div>
                </div>
                <div className={"lineBlock"}/>
                <div className={"lineX"}>
                    <animated.span style={lineX}>
                        <FontAwesomeIcon icon={faSortUp}/>
                    </animated.span>
                    <animated.label style={lineLabels}>Verfügbarkeit</animated.label>
                </div>
            </div>
        </div>

    )
}
