import {IonPage} from '@ionic/react';
import React from 'react';
import {withRouter} from "react-router";
import Header from "../components/Header";
import {ContentWebToPrint} from "./webtoprint";

const PageW2p: React.FC = () => {

    return (
        <IonPage>

            <Header name={"w2p"}/>

                <ContentWebToPrint />


        </IonPage>
    );
};

export default withRouter(PageW2p);

