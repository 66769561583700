import React, {useEffect, useState} from 'react';
import "./ContentBox.scss";
import {loadMD} from "../data/helper";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

export type TContentBoxLayout = "col1" | "col2";

export interface IContentBox {
    title?: string;
    mdFile: string;
    cssClasses?: string;
    layout?: TContentBoxLayout
}

export const ContentBox: React.FC<IContentBox> = ({
                                                      title = "",
                                                      mdFile,
                                                      layout = "1col",
                                                      cssClasses = ""
                                                  }) => {

    const [content, setContent] = useState("");
    useEffect(() => {
        // Load Teaser Text
        loadMD(mdFile).then((r) => {
            setContent(r)
        })
    }, [mdFile])

    return (
        <>
            {title.length > 0 && <h1>{title}</h1>}
            <div className={`ContentBox ${layout} ${cssClasses}`}>
                <ReactMarkdown plugins={[[gfm]]} children={content}/>
            </div>
        </>
    );
};
