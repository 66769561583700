import React from 'react';
import "./ContentBlock.scss";

export const ContentBlock: React.FC = ({
                                           children,
                                       }) => {

    return (
        <div className={`ContentBlock`}>
            <div>
                {children}
            </div>
        </div>
    );
};
