import React, {useRef, useState} from 'react';
import "./why-bitsinmotion.scss";
import {IonContent} from "@ionic/react";
import {TeaserW2p} from "../components/content/teaser_w2p";
import {LazyLoader} from "../components/LazyLoad";
import {WhyBitsinmotion} from "../components/content/whybitsinmotion/WhyBitsinmotion";
import {Spacer} from "../components/Spacer";
import {ContentPWATeaser} from "../components/content/PWATeaser/ContentPWATeaser";
import {AppFooter} from "../components/footer/AppFooter";

export const ContentWhyBitsinmotion: React.FC = () => {

    const containerRef = useRef(null);
    const [containerScroll, setContainerScroll] = useState(2);

    return (
        <IonContent fullscreen ref={containerRef} id="scrollContainer" scrollEvents={true} onIonScroll={(e) => {
            setContainerScroll(e.detail.scrollTop);
        }}>

            <ContentPWATeaser/>

            <Spacer height={50}/>

            <LazyLoader container={containerRef} height={500} offset={0} containerScroll={containerScroll}>
                <TeaserW2p/>
            </LazyLoader>

            <LazyLoader container={containerRef} height={500} offset={0} containerScroll={containerScroll}>
                <WhyBitsinmotion/>
            </LazyLoader>

            <AppFooter/>

        </IonContent>
    );
};

