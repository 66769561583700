import React, {useEffect, useState} from 'react';
import {IonImg} from "@ionic/react";
import cloud1 from "./xcloud1.svg";
import cloud2 from "./xcloud2.svg";
import cloud3 from "./xcloud3.svg";
import logo from "../../assets/icons/logospacesingle.png";
import "./BitsCloud.scss";



 const BitsCloud: React.FC = () => {

     const [showCloudText, setShowCloudText] = useState("");
     const [showCloud1, setShowCloud1] = useState("");
     const [showCloud2, setShowCloud2] = useState("");
     const [showCloud3, setShowCloud3] = useState("");
     const [showLogo, setLogo] = useState("");

     useEffect(() => {

         setTimeout(() => setShowCloudText("show"), 700)
         setTimeout(() => setShowCloud1("show"), 800)
         setTimeout(() => setShowCloud2("show"), 900)
         setTimeout(() => setShowCloud3("show"), 1000)
         setTimeout(() => setLogo("show"), 1200)

     }, [])

    return <>

        {/*Step 2 - Cloud*/}
        <div className="cloudApi">
            <IonImg className={`cloud1 ${showCloud1}`} src={cloud1}/>
            <IonImg className={`cloud2 ${showCloud2}`} src={cloud2}/>
            <IonImg className={`cloud3 ${showCloud3}`} src={cloud3}/>
            <IonImg className={`logo ${showLogo}`} src={logo}/>
        </div>

    </>
}


export default BitsCloud;
