import {IonPage} from '@ionic/react';
import React from 'react';
// import "./Home.scss";
import {withRouter} from "react-router";
import Header from "../components/Header";
import {ContentWhyBitsinmotion} from "./why-bitsinmotion";

const PageHome: React.FC = (props) => {

    return (
        <IonPage>
            <Header name={"why"}/>
                <ContentWhyBitsinmotion/>
        </IonPage>
    );
};

export default withRouter(PageHome);

