import React, {useCallback, useEffect, useRef, useState} from 'react';
import "./LazyLoad.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDoubleDown} from "@fortawesome/pro-duotone-svg-icons";
import {useWindowResize} from "./windowResize";

export interface ILazyLoaderProps {
	container: React.MutableRefObject<null>,
	height: number,
	offset: number,
	containerScroll: number
}

export const LazyLoader: React.FC<ILazyLoaderProps> = (props) => {

	const [showContent, setShowContent] = useState(false);

	const itemRef = useRef(null);

	const windowSize = useWindowResize();

	const handleShow = useCallback(() => {

		if (props.container && props.container!.current!) {

			// Get scroll container & item
			const container = props.container!.current! as HTMLIonContentElement;
			const item = itemRef.current! as HTMLDivElement;

			if (container.offsetHeight >= 10) {

				if (props.containerScroll + container.offsetHeight + -Math.abs(props.offset) >= item.offsetTop) {
					setShowContent(true);
				}
			}
		}

	}, [props.containerScroll, props.offset, props.container])

	// @ts-ignore
	useEffect(() => {
		handleShow();
	}, [props.containerScroll, windowSize, handleShow])

	useEffect(() => {
		handleShow();
		setTimeout(() => handleShow(), 200)
	})

	return (
		<div className={`LazyLoader ${showContent ? "show" : ""}`} ref={itemRef} style={{
			minHeight: `${props.height}px`,
			height: 'min-content',
			maxHeight: `min-content`,
		}}>
			{showContent && props.children}
			{!showContent && <div className="placeholder"><FontAwesomeIcon icon={faChevronDoubleDown}/></div>}
		</div>
	);
};
