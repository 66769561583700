import {IonPage} from '@ionic/react';
import React from 'react';
import "./Page.scss";
import Header from "../../components/Header";
import {withRouter} from "react-router";
import {AppFooter} from "../../components/footer/AppFooter";
import {Privacy} from "../privacy";

const PagePrivacy: React.FC = (props) => {

    return (
        <IonPage>
            <Header name={""}/>
              <Privacy />
            <AppFooter/>
        </IonPage>
    );
};

export default withRouter(PagePrivacy);

