import React, {useEffect, useState} from 'react';
import "./animationw2p.scss";
import {IonImg} from "@ionic/react";

import arrow1 from "../../assets/animationw2p/arrow1.svg";
import arrow2 from "../../assets/animationw2p/arrow2.svg";
import arrow3 from "../../assets/animationw2p/arrow3.svg";
import browser from "../../assets/animationw2p/browser2.svg";
import cloud1 from "../../assets/animationw2p/cloud1.svg";
import cloud2 from "../../assets/animationw2p/cloud2.svg";
import cloud3 from "../../assets/animationw2p/cloud3.svg";
import mobile from "../../assets/animationw2p/mobile.svg";
import printer from "../../assets/animationw2p/printer.svg";
import socialmedia from "../../assets/animationw2p/socialmedia.svg";

import printFlyer from "../../assets/animationw2p/printFlyer.svg";
import printCalendar from "../../assets/animationw2p/printCalendar.svg";
import printBag from "../../assets/animationw2p/printBag.svg";
import photo from "../../assets/animationw2p/photo.svg";
import like from "../../assets/animationw2p/like.svg";
import logo from "../../assets/icons/logospacesingle.png";
import {useWindowResize} from "../windowResize";

export const AnimationW2P: React.FC = () => {

	const [showBrowser, setShowBrowser] = useState("");
	const [showMobile, setShowMobile] = useState("");
	const [showStep1Text, setShowStep1Text] = useState("");

	const [showPrintFlyer, setShowPrintFlyer] = useState("");
	const [showPrintFlyerFly, setShowPrintFlyerFly] = useState("");

	const [showPrintCalendar, setShowPrintCalendar] = useState("");
	const [showPrintCalendarFly, setShowPrintCalendarFly] = useState("");

	const [showPrintBag, setShowPrintBag] = useState("");
	const [showPrintBagFly, setShowPrintBagFly] = useState("");

	const [showPhoto, setShowPhoto] = useState("");
	const [showPhotoFly, setShowPhotoFly] = useState("");
	const [showLike, setShowLike] = useState("");

	const [showArrow1, setShowArrow1] = useState("");

	const [showCloudText, setShowCloudText] = useState("");
	const [showCloud1, setShowCloud1] = useState("");
	const [showCloud2, setShowCloud2] = useState("");
	const [showCloud3, setShowCloud3] = useState("");
	const [showLogo, setLogo] = useState("");

	const [showPrinterText, setShowPrinterText] = useState("");
	const [showArrow3, setShowArrow3] = useState("");
	const [showTextPDF, setShowTextPDF] = useState("");
	const [showPrinter, setShowPrinter] = useState("");

	const [showSocialText, setShowSocialText] = useState("");
	const [showArrow2, setShowArrow2] = useState("");
	const [showSocialmedia, setShowSocialMedia] = useState("");
	const [showBrandedText, setShowBrandedText] = useState("");
	const [scale, setScale] = useState(1);


	useEffect(() => {

		setTimeout(() => setShowBrowser("show"), 200)
		setTimeout(() => setShowMobile("show"), 300)
		setTimeout(() => setShowStep1Text("show"), 400)

		setTimeout(() => setShowPrintFlyer("show"), 2500)
		setTimeout(() => setShowPrintFlyerFly("fly"), 3500)

		setTimeout(() => setShowPrintCalendar("show"), 2600)
		setTimeout(() => setShowPrintCalendarFly("fly"), 3700)
		setTimeout(() => setShowPrintBag("show"), 2700)
		setTimeout(() => setShowPrintBagFly("fly"), 3800)
		setTimeout(() => setShowPhoto("show"), 4000)
		setTimeout(() => setShowPhotoFly("fly"), 4200)
		setTimeout(() => setShowLike("show"), 4500)

		setTimeout(() => setShowArrow1("show"), 500)

		setTimeout(() => setShowCloudText("show"), 700)
		setTimeout(() => setShowCloud1("show"), 800)
		setTimeout(() => setShowCloud2("show"), 900)
		setTimeout(() => setShowCloud3("show"), 1000)
		setTimeout(() => setLogo("show"), 1200)

		setTimeout(() => setShowArrow3("show"), 1200)
		setTimeout(() => setShowPrinterText("show"), 1300)
		setTimeout(() => setShowPrinter("show"), 1400)
		setTimeout(() => setShowTextPDF("show"), 1500)

		setTimeout(() => setShowArrow2("show"), 1800)
		setTimeout(() => setShowSocialText("show"), 1900)
		setTimeout(() => setShowSocialMedia("show"), 2000)
		setTimeout(() => setShowBrandedText("show"), 2100)
	}, [])

	const size = useWindowResize();

	useEffect(() => {

		// Resize
		if (size !== undefined) {
			let scaleLocal = size.width! / 1200;

			scaleLocal = scaleLocal + 0.070;

			if (scaleLocal > 1) scaleLocal = 1;
			setScale(scaleLocal);
		}

	}, [size])

	return (

		<div className="AnimationW2p">

			<div className="inner" style={{
				transform: `scale(${scale})`
			}}>

				{/*Step 1 - Devices Editor*/}
				<IonImg className={`browser ${showBrowser}`} src={browser}/>
				<IonImg className={`mobile ${showMobile}`} src={mobile}/>
				<IonImg className={`printFlyer ${showPrintFlyer} ${showPrintFlyerFly}`} src={printFlyer}/>
				<IonImg className={`printCalendar ${showPrintCalendar} ${showPrintCalendarFly}`} src={printCalendar}/>
				<IonImg className={`printBag ${showPrintBag} ${showPrintBagFly}`} src={printBag}/>
				<IonImg className={`photo ${showPhoto} ${showPhotoFly}`} src={photo}/>
				<IonImg className={`like ${showLike} `} src={like}/>
				<span className={`textEditor ${showStep1Text}`}>Dein System</span>

				<IonImg className={`arrow1 ${showArrow1}`} src={arrow1}/>

				{/*Step 2 - Cloud*/}
				<div className="cloudApi">
					<span className={`textCloud ${showCloudText}`}>Cloud Rendering</span>
					<IonImg className={`cloud1 ${showCloud1}`} src={cloud1}/>
					<IonImg className={`cloud2 ${showCloud2}`} src={cloud2}/>
					<IonImg className={`cloud3 ${showCloud3}`} src={cloud3}/>
					<IonImg className={`logo ${showLogo}`} src={logo}/>
				</div>

				<div className="outPrint">
					<span className={`textPrint ${showPrinterText}`}>Druckerei</span>
					<span className={`textPDF ${showTextPDF}`}>Druck-PDF</span>
					<IonImg className={`arrow3 ${showArrow3}`} src={arrow3}/>
					<IonImg className={`printer ${showPrinter}`} src={printer}/>
				</div>

				<div className="outSocial">
					<span className={`textSocial ${showSocialText}`}>Social Marketing</span>
					<span className={`textBranded ${showBrandedText}`}>Branded Image</span>
					<IonImg className={`arrow2 ${showArrow2}`} src={arrow2}/>
					<IonImg className={`socialmedia ${showSocialmedia}`} src={socialmedia}/>
				</div>

			</div>
		</div>
	);
};


