import React, {useEffect, useState} from 'react';
import "./ProductItem.scss";
import {loadMD} from "../../../data/helper";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

export interface IProductItem {
    mdFile: string;
}

export const ProductItem: React.FC<IProductItem> = ({
                                                        mdFile
                                                    }) => {

    const [content, setContent] = useState("");
    useEffect(() => {
        // Load Teaser Text
        loadMD(mdFile).then((r) => {
            setContent(r)
        })
    }, [mdFile])

    return (
        <div className="ProductItem">
            <ReactMarkdown plugins={[[gfm]]} children={content}/>
        </div>

    );
};
