import React, {useEffect, useState} from 'react';
import "./RenderAPITeaser.scss";
import {loadMD} from "../data/helper";
import {IonImg} from "@ionic/react";

import pflogo from "../assets/icons/pflogo.webp";
import blLogo from "../assets/icons/logospacesingle.png";
import plus from "../assets/icons/plus.svg";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import text from "./../cms/textRenderingApi.md";

export const RenderAPITeaser: React.FC = () => {

    const [showBL, setShowBL] = useState("");
    const [showPlus, setShowPlus] = useState("");
    const [showPF, setShowPF] = useState("");


    useEffect(() => {

        setTimeout(() => setShowBL("show"), 200);
        setTimeout(() => setShowPlus("show"), 500);
        setTimeout(() => setShowPF("show"), 750);

    }, []);

    const [content, setContent] = useState("");
    useEffect(() => {
        // Load Teaser Text
        loadMD(text).then((r) => {
            setContent(r)
        })
    }, [])

    return (
        <div className="RenderAPITeaser">

            <h1>Pageflex Server & Offline Rendering</h1>
            <span className="topLine">Schnell, zuverlässig und flexibel</span>

            <div className="columns">

                <ReactMarkdown plugins={[[gfm]]} children={content}/>

                <div className="teaser">
                    <IonImg className={`blLogo ${showBL}`} src={blLogo}/>
                    <IonImg className={`plus ${showPlus}`} src={plus}/>
                    <IonImg className={`pfLogo ${showPF}`} src={pflogo}/>
                </div>
            </div>
        </div>
    );
};
