import React from 'react';
import {IonContent} from "@ionic/react";
import {AppFooter} from "../components/footer/AppFooter";
import "./imprint.scss";

export const Privacy: React.FC = () => {
    return <>

        <IonContent className="padding" fullscreen id="scrollContainer" scrollEvents={true}>
            <div>
             <h3>Datenschutzerklärung / Privacy Policy</h3>

                <br />

                Thank you for visiting our online presence. Protection of your privacy is very important to us. Below you will find extensive information about how we handle your data.  <br />
                <br />
                1. Access data and hosting  <br />
                You may visit our website without revealing any personal information. With every visit on the website, the web server stores automatically only a so-called server log file which contains e.g. the name of the requested file, your IP address, the date and time of the request, the volume of data transferred and the requesting provider (access data), and documents the request.  <br />
                <br />
                These access data are analysed exclusively for the purpose of ensuring the smooth operation of the website and improving our offer. This serves according to art. 6 (1) 1 lit f GDPR the protection of our legitimate interests in the proper presentation of our offer that are overriding in the process of balancing of interests. All access data are deleted no later than seven days after the end of your visit on our website.  <br />
                <br />
                2. Contact possibilities and your rights  <br />
                You are entitled to be obtain free-of-charge information concerning data stored about your person and, as the case may be, to correct, restrict the processing, enable the portability of, or delete those data.  <br />
                <br />
                If you have any questions about how we collect, process or use your personal data, want to enquire about, correct, block or delete your data, or withdraw any consents you have given, or opt-out of any particular data use, please contact us directly using the contact data provided in our site notice.  <br />
                <br />
                You may also submit a complaint to the responsible data protection supervisory authority.  <br />
                <br />
                <h3>Right to object</h3>  <br />
                If we process personal data as described above to protect our legitimate interets that are overriding in the process of balancing of interests, you may object to such data processing with future effect. If your data are processed for direct marketing purposes, you may exercise this right at any time as described above. If your data are processed for other purposes, you have the right to object only on grounds relating to your particular situation.  <br />
                <br />
                After you have exercised your right to object, we will no longer process your personal data for such purposes unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or for the establishment, exercise or defence of legal claims.  <br />
                <br />
                This does not apply to the processing of personal data for direct marketing purposes. In such a case we will no longer process your personal data for such purposes.  <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>

        </IonContent>
    </>
}
