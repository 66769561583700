import Menu from './components/Menu';
import React from 'react';
import {Provider} from 'react-redux';
import {IonApp, IonRouterOutlet, IonSplitPane} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Route} from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import "./scss/Main.scss";

/* Theme variables */
import './theme/variables.css';
import storeMain from "./store/main";
import PageImprint from "./pages/home/PageImprint";
import PageHome from "./pages/page_home";
import PageW2p from "./pages/page_w2p";
import PageApps from "./pages/page_webapps"
import PagePrivacy from "./pages/home/PagePrivacy";

const store = storeMain;

const App: React.FC = () => {

    // @ts-ignore
    return (<Provider store={store}>
            <IonApp>
                <IonReactRouter>
                    <IonSplitPane contentId="main" when="(min-width: 12000px)">
                        <Menu/>
                        <IonRouterOutlet id="main" animated={false}>
                            <Route path="/" component={PageHome} exact/>
                            <Route path="/imprint" component={PageImprint} exact/>
                            <Route path="/privacy" component={PagePrivacy} exact/>
                            <Route path="/why-bitsinmotion" component={PageHome} exact/>
                            <Route path="/webtoprint" component={PageW2p} exact/>
                            <Route path="/webapps" component={PageApps} exact/>


                            {/*<Redirect from="/" to={`/page/${appConfig.pageDefault}`} exact/>*/}
                            {/*<Redirect from="/page" to={`/`} />*/}
                        </IonRouterOutlet>
                    </IonSplitPane>
                </IonReactRouter>
            </IonApp>
        </Provider>
    );
};

export default App;
