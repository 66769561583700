import React, {useMemo} from 'react';
import {Grid} from "@material-ui/core";
import {IonChip} from '@ionic/react';
import { v4 as uuidv4 } from 'uuid';

import "./WebAppComparisonTable.scss";

interface IWebAppComparisonTableData {
    label: string;
    pwa: any;
    native: any;
}

 const WebAppComparisonTable: React.FC = () => {

    const data: IWebAppComparisonTableData[] = useMemo(() => {

        return [
            {
                label: "App Store Vorraussetzung",
                pwa: <IonChip color={"success"}>Ja</IonChip>,
                native: <IonChip color={"warning"}>Ja</IonChip>
            },
            {
                label: "App Store Verfügbarkeit",
                pwa: <IonChip color={"success"}>Ja</IonChip>,
                native: <IonChip color={"success"}>Ja</IonChip>
            },
            {
                label: "Installierbar",
                pwa: <IonChip color={"success"}>Optional</IonChip>,
                native: <IonChip color={"success"}>Obligatorisch</IonChip>
            },
            {
                label: "Updates AppStores erfordern Prüfung",
                pwa: <IonChip color={"success"}>Selten</IonChip>,
                native: <IonChip color={"warning"}>Ja</IonChip>
            },
            {
                label: "Cross-Plattform Android, iOS, Windows",
                pwa: <IonChip color={"success"}>Ja</IonChip>,
                native: <IonChip color={"danger"}>Nein</IonChip>
            },
            {
                label: "Über Browser und AppStore verfügbar",
                pwa: <IonChip color={"success"}>Ja</IonChip>,
                native: <IonChip color={"danger"}>Nur AppStores</IonChip>
            },
            {
                label: "Website + App in einer Applikation",
                pwa: <IonChip color={"success"}>Ja</IonChip>,
                native: <IonChip color={"danger"}>Nein</IonChip>
            },
            {
                label: "Aufwand App + Admin Backend Android, iOS, Browser",
                pwa: <IonChip color={"success"}>~100%</IonChip>,
                native: <IonChip color={"danger"}>~300%</IonChip>
            },
            {
                label: "Native Funktionen verfügbar",
                pwa: <div key={uuidv4()} className={"chipList"}>
                    <IonChip key={uuidv4()} color={"success"}>Add-to-Homescreen</IonChip>
                    <IonChip key={uuidv4()} color={"success"}>Kamera</IonChip>
                    <IonChip key={uuidv4()} color={"success"}>Standort</IonChip>
                    <IonChip key={uuidv4()} color={"success"}>Neigungssensoren</IonChip>
                    <IonChip key={uuidv4()} color={"success"}>Mirkofon</IonChip>
                    <IonChip key={uuidv4()} color={"warning"}>Push-Nachrichten</IonChip>
                    <IonChip key={uuidv4()} color={"warning"}>Biometrik</IonChip>
                    <IonChip key={uuidv4()} color={"warning"}>Background-Sync</IonChip>
                </div>,
                native: <IonChip color={"success"}>Alle</IonChip>
            }
        ]

    }, [])

    return (
        <div className={"WebAppComparisonTable"}>

            <h1>Vergleich WebApp zu nativer App</h1>

            <Grid container spacing={3}>

                <Grid className={"label"} key={uuidv4()} item xs={6}/>
                <Grid className={"columnhead"} key={uuidv4()} item xs={3}>WebApp</Grid>
                <Grid className={"columnhead"} key={uuidv4()} item xs={3}>Nativ</Grid>

                {data.map((item) => {
                    return (
                        <>
                            <Grid className={"label"} key={uuidv4()} item xs={6}>{item.label}</Grid>
                            <Grid className={"tags"} key={uuidv4()} item xs={3}>{item.pwa}</Grid>
                            <Grid className={"tags"} key={uuidv4()} item xs={3}>{item.native}</Grid>
                        </>
                    )
                })}

            </Grid>
        </div>
    );
};


export default  WebAppComparisonTable;
