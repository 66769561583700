import React from 'react';
import {IonContent} from "@ionic/react";
import "./imprint.scss";

export const Imprint: React.FC = () => {
    return <>

        <IonContent className="padding" fullscreen id="scrollContainer" scrollEvents={true}>
            <div>
                <h3>Impressum:</h3>
                <br />
            bitsinmotion GmbH<br />
            Nikolaus-Kopernikus-Str. 6<br />
            55129 Mainz/Germany<br />
                <br /><br />

            Geschäftsführer Adrian Stanek, Bernd Stutter<br />
            Amtsgericht Mainz HRB 7828<br />
            St-Nr.: 2665103567<br />
            Ust.-Id.-Nr. DE 220877192<br />
            <br />
            <br /><br />
            Telefon 06131.95053-20<br />
            welcome[at]bitsinmotion.de<br />
            <br /><br />

            </div>

        </IonContent>
    </>
}
