import React, {useEffect, useState} from 'react';
import {Spacer} from "../../Spacer";
import {IonButton, IonLabel} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-duotone-svg-icons";
import "./ContentPWATeaser.scss";
import {createLink} from "../../../data/helper";
import BitsCloud from "../../BitsCloud/BitsCloud";


export const ContentPWATeaser: React.FC = () => {

    const [show, setShow] = useState(false);
    const [showH1, setShowH1] = useState(false);
    const [showH2, setShowH2] = useState(false);
    const [showAction, setShowAction] = useState(false);
    const [showAction2, setShowAction2] = useState(false);
    const [showLogo, setShowLogo] = useState("");

    useEffect(() => {
        setTimeout(() => setShow(true), 100);
        setTimeout(() => setShowH1(true), 350);
        setTimeout(() => setShowH2(true), 400);
        setTimeout(() => setShowAction(true), 750);
        setTimeout(() => setShowAction2(true), 900);

        setTimeout(() => setShowLogo("show"), 900);
    }, [])

    return (
        <div className="WhyBitsinmotion">
            <div className={`inner ${(show) ? "show" : ""}`}>

                <h1 className={`${(showH1) ? "show" : ""}`}>Website + App = Progressive Web App </h1>
                <h2 className={`${(showH2) ? "show" : ""}`}>
                    Eine Plattform, alle Betriebssysteme. Preiswert und effizient zur perfekten User Experience!
                </h2>

                <Spacer height={20}/>

                <IonButton className={`${(showAction) ? "show" : ""}`} color="light" expand={"block"} routerLink={createLink(`webapps`)}>
                    <FontAwesomeIcon icon={faSearch}/>
                    <IonLabel>Jetzt ansehen</IonLabel>
                </IonButton>

                <div className="puzzle">
                    <BitsCloud />
                </div>

                <Spacer height={50}/>
            </div>
        </div>

    );
};
