import React, {useEffect, useState} from 'react';
import "./TeaserW2p.scss";
import {AppHelper} from "../../data/helper";
import {IonButton, IonImg, IonLabel} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {useDispatch} from "react-redux";
import {Spacer} from "../Spacer";

import printFlyer from "../../assets/animationw2p/printFlyer.svg";
import mobile from "../../assets/animationw2p/mobile.svg";
import cloud2 from "../../assets/animationw2p/cloud2.svg";
import logo from "../../assets/icons/logospacesingle.png";
import browser from "../../assets/animationw2p/browser2.svg";
import arrow2 from "../../assets/animationw2p/arrow2.svg";
import arrow3 from "../../assets/animationw2p/arrow3.svg";
import {useWindowResize} from "../windowResize";
import {setPage} from "../../store/appStore";
import {ContentBlock} from "../ContentBlock";

export const TeaserW2p: React.FC = () => {

    const appHelper = new AppHelper();

    const dispatch = useDispatch();

    const size = useWindowResize();
    const [scale, setScale] = useState(1);

    useEffect(() => {

        // Resize
        if (size !== undefined) {
            let scaleLocal = size.width! / 1200;
            if (scaleLocal > 1) scaleLocal = 1;
            setScale(scaleLocal);
        }

    }, [size])

    return (

        <ContentBlock>

            <div className="TeaserW2p">
                <span className={"columnTitle"}>Web-to-Print</span>

                <h1>15 Jahre Web-to-Print Expertise</h1>
                <h2>... auf den Punkt gebracht!</h2>

                <div className="columns">

                    <p>
                        Von Data-Driven Katalogproduktionen, über formatunabhängige Anzeigen bis hin zu POS Material jeder Art. Wir digitalisieren jede Druckanwendung. Versprochen.

                    </p>


                    <IonButton className={`sublineButton`} color="primary" expand={"block"} onClick={() => {
                        dispatch(setPage({page: "webtoprint"}))
                    }}>
                        <IonLabel>jetzt mehr erfahren</IonLabel>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </IonButton>
                </div>

                <Spacer height={30}/>

                <div className="teaserWrapper">
                    <div className="teaserStage" style={{
                        transform: `scale(${scale})`
                    }}>
                        <IonImg className="browser" src={browser}/>
                        <IonImg className="mobil" src={mobile}/>
                        <IonImg className="arrow1" src={arrow3}/>
                        <IonImg className="cloud" src={cloud2}/>
                        <IonImg className="logo" src={logo}/>
                        <IonImg className="arrow2" src={arrow2}/>
                        <IonImg className="flyer" src={printFlyer}/>
                    </div>
                </div>
            </div>

        </ContentBlock>
    );
};


