import React from 'react';
import {Grid, Link} from "@material-ui/core";
import "./AppFooter.scss";
import {createLink} from "../../data/helper";

export const AppFooter: React.FC = () => {

    return (
        <div className={"AppFooter"}>

            <div className={"footerInner"}>

                <Grid container>

                    <Grid item xs={6} md={4}>
                        <div className={"footerColumn"}>
                            {/*<h3>Services</h3>*/}
                            {/*<Link href={createLink(`webapps`)}>WebApps / PWA</Link>*/}
                            {/*<Link href={createLink(`webtoprint`)}>W2P Übersicht</Link>*/}
                        </div>
                    </Grid>

                    <Grid item xs={6} md={4}>
                        <div className={"footerColumn"}>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className={"footerColumn"}>

                            <h3>Unternehmen</h3>
                            {/*<Link href={createLink(`customers`)}>Referenzen & Kunden</Link>*/}
                            {/*<Link href={createLink(`showroom`)}>Showroom</Link>*/}
                            {/*<Link href={createLink(`showroom`)}>Über uns</Link>*/}
                            <Link href={createLink(`imprint`)}>Impressum</Link>
                            <Link href={createLink(`privacy`)}>Datenschutz</Link>
                        </div>
                    </Grid>
                </Grid>

            </div>
        </div>
    );
};
