import React from 'react';
import "./W2PGuide.scss";
import {Grid} from "@material-ui/core";
import {IonButton, IonLabel} from '@ionic/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGraduationCap} from "@fortawesome/pro-duotone-svg-icons";

import textIntegration from "../../../cms/w2pguide/guideIntegration.md";
import textB2B from "../../../cms/w2pguide/guideB2b.md";
import textMarketingcenter from "../../../cms/w2pguide/guideMarketingcenter.md";

import {DisplayMD} from "../../DisplayMD";
import {animated, config, useSpring} from "react-spring";

export const W2PGuide: React.FC = () => {

    // https://www.react-spring.io/docs/hooks/api
    const headlineProps = useSpring({
        opacity: 1,
        left: 0,
        from: {
            opacity: 0,
            left: -100
        },
        delay: 500,
        config: config.slow
    })


    const guideProps = {
        opacity: 1,
        bottom: 0,
        from: {
            opacity: 0,
            bottom: -100
        },
        delay: 0,
        config: config.wobbly
    };

    const guide1 = useSpring({
        ...guideProps,
        delay: 750
    })

    const guide2 = useSpring({
        ...guideProps,
        delay: 1000
    })

    const guide3 = useSpring({
        ...guideProps,
        delay: 1250
    })


    return (
        <div className="W2PGuide">

            <div className={"guideHeader"}>
                <animated.span style={headlineProps}>Unsere Web-to-Print Hauptprodukte</animated.span>
            </div>

            <div className={"gradientW2P"}/>

            <div className={"contentGuide"}>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6} md={4}>

                        <animated.div style={guide1} className={"item"}>

                            <DisplayMD input={textIntegration}/>

                            <IonButton size={"default"} color={"primary"} onClick={() => {
                            }}>
                                <FontAwesomeIcon icon={faGraduationCap}/>
                                <IonLabel>Mehr erfahren</IonLabel>
                            </IonButton>
                        </animated.div>

                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <animated.div style={guide2} className={"item"}>
                            <DisplayMD input={textB2B}/>
                            <IonButton size={"default"} color={"primary"} onClick={() => {
                            }}>
                                <FontAwesomeIcon icon={faGraduationCap}/>
                                <IonLabel>Mehr erfahren</IonLabel>
                            </IonButton>
                        </animated.div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <animated.div style={guide3} className={"item"}>
                            <DisplayMD input={textMarketingcenter}/>
                            <IonButton size={"default"} color={"primary"} onClick={() => {
                            }}>
                                <FontAwesomeIcon icon={faGraduationCap}/>
                                <IonLabel>Mehr erfahren</IonLabel>
                            </IonButton>
                        </animated.div>
                    </Grid>

                </Grid>
            </div>


        </div>

    );
};
