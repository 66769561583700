import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {appReducer, IAppState} from "./appStore";

export interface RootState {
    appReducer: IAppState,
}

const rootReducer = combineReducers({appReducer});

// @ts-ignore
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(
        thunk,
    ),
);

const createMainStore = () => createStore(rootReducer, {}, enhancer);
const storeMain = createMainStore();
export default storeMain;

