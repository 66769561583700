import React, {useRef, useState} from 'react';
import "./webtoprint.scss";
import {AnimationW2P} from "../components/content/animationw2p";
import {Spacer} from "../components/Spacer";
import {Integrations} from "../components/Integrations";
import {ContentBox} from "../components/ContentBox";
import {RenderAPITeaser} from "../components/RenderAPITeaser";
import {IonContent} from "@ionic/react";
import {LazyLoader} from "../components/LazyLoad";
import integrationText from "../cms/textW2PIntegrated.md";
import {ProductItem} from "../components/content/ProductItem/ProductItem";
import textProductB2B from "./../cms/products/textProduct_B2B.md";
import textProductIntegrations from "../cms/products/textProduct_Integrations.md";
import textProductMarketingportal from "../cms/products/textProduct_Marketingportal.md";
import textProductDigital from "../cms/products/textProduct_Digital.md";
import textProductDataDriven from "../cms/products/textProduct_DataDrivenPublishing.md";
import textProductKatalog from "../cms/products/textProduct_Katalog.md";
import {Grid} from "@material-ui/core";
import {W2PGuide} from "../components/content/W2PGuide/W2PGuide";
import {AppFooter} from "../components/footer/AppFooter";

export const ContentWebToPrint: React.FC = () => {

    const containerRef = useRef(null);
    const [containerScroll, setContainerScroll] = useState(2);

    return (

        <IonContent fullscreen ref={containerRef} id="scrollContainer" scrollEvents={true} onIonScroll={(e) => {
            setContainerScroll(e.detail.scrollTop);
        }}>

            <div className="animationW2pWrapper">
                <AnimationW2P/>
            </div>

            <LazyLoader container={containerRef} height={700} offset={0} containerScroll={containerScroll}>
                <div className="content ">
                    <div className="inner expand noPadding">
                        <W2PGuide/>
                    </div>
                </div>
            </LazyLoader>

            <Spacer height={50}/>

            <LazyLoader container={containerRef} height={320} offset={0} containerScroll={containerScroll}>
                <div className="content">
                    <div className="inner">
                        <ContentBox mdFile={integrationText} layout={"col2"} title={"Web-to-Print auf Ihre Bedürfnisse abgestimmt"}/>

                    </div>
                </div>
            </LazyLoader>

            <LazyLoader container={containerRef} height={360} offset={0} containerScroll={containerScroll}>
                <div className="content">
                    <div className="inner">
                        <Integrations/>
                    </div>
                </div>
            </LazyLoader>

            <Spacer height={50}/>

            <LazyLoader container={containerRef} height={300} offset={0} containerScroll={containerScroll}>
                <div className="content">
                    <div className="inner">

                        <h1>Übersicht der standard Lösungen</h1>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={6}>
                                <ProductItem mdFile={textProductB2B}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <ProductItem mdFile={textProductKatalog}/>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </LazyLoader>

            <Spacer height={30}/>

            <LazyLoader container={containerRef} height={300} offset={0} containerScroll={containerScroll}>
                <div className="content">
                    <div className="inner">

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={6}>
                                <ProductItem mdFile={textProductIntegrations}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <ProductItem mdFile={textProductMarketingportal}/>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </LazyLoader>

            <Spacer height={30}/>

            <LazyLoader container={containerRef} height={300} offset={0} containerScroll={containerScroll}>
                <div className="content">
                    <div className="inner">

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={6}>
                                <ProductItem mdFile={textProductDataDriven}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <ProductItem mdFile={textProductDigital}/>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </LazyLoader>


            <Spacer height={50}/>

            <LazyLoader container={containerRef} height={300} offset={0} containerScroll={containerScroll}>
                <div className="content">
                    <div className="inner">
                        <RenderAPITeaser/>
                    </div>
                </div>
            </LazyLoader>

            <AppFooter/>

        </IonContent>
    );
};

