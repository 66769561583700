import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import "./Integrations.scss";

import integrations_html5 from "../assets/icons/integrations/integration_html5.svg";
import integrations_punchout from "../assets/icons/integrations/integration_punchout.svg";
import integrations_iFrame from "../assets/icons/integrations/integration_iframe.svg";
import integrations_sidebyside from "../assets/icons/integrations/integration_sidebyside.svg";
import {loadMD} from "../data/helper";
import {useDispatch} from "react-redux";
import {setPage} from "../store/appStore";
import {animated, config, useSpring} from "react-spring";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import integrationsHTML5 from "../cms/integrations/integrationHTML5.md";
import integrationIFrame from "../cms/integrations/integrationIFrame.md";
import integrationPunchOut from "../cms/integrations/integrationPunchOut.md";
import integrationSideBySide from "../cms/integrations/integrationSideBySide.md";


export const Integrations: React.FC = () => {

    const integration1 = useSpring({
        opacity: 1,
        left: 0,
        from: {
            opacity: 0,
            left: -100
        },
        delay: 250,
        config: config.molasses
    })

    const integration2 = useSpring({
        opacity: 1,
        bottom: 0,
        from: {
            opacity: 0,
            bottom: -100
        },
        delay: 300,
        config: config.molasses
    })

    const integration3 = useSpring({
        opacity: 1,
        bottom: 0,
        from: {
            opacity: 0,
            bottom: -100
        },
        delay: 350,
        config: config.molasses
    })

    const integration4 = useSpring({
        opacity: 1,
        right: 0,
        from: {
            opacity: 0,
            right: -100
        },
        delay: 400,
        config: config.molasses
    })

    return (

        <div className="Integrations">

            <animated.div style={integration1}>
                <IntegrationsCard icon={integrations_html5} mdFile={integrationsHTML5} title={"HTML5 Modul"} subtitle={"Direkt einbinden"} pageName={"customers"}/>
            </animated.div>

            <animated.div style={integration2}>
                <IntegrationsCard icon={integrations_sidebyside} mdFile={integrationSideBySide} title={"Side-by-Side"} subtitle={"Individuelles Portal"} pageName={"customers"}/>
            </animated.div>

            <animated.div style={integration3}>
                <IntegrationsCard icon={integrations_iFrame} mdFile={integrationIFrame} title={"iFrame"} subtitle={"Seite-in-Seite einbinden"} pageName={"customers"}/>
            </animated.div>

            <animated.div style={integration4}>
                <IntegrationsCard icon={integrations_punchout} mdFile={integrationPunchOut} title={"Punch-Out"} subtitle={"Absprung in Cloud und zurück"} pageName={"customers"}/>
            </animated.div>
        </div>

    );
};

export interface IIntegrationsCards {
    icon: string;
    title: string;
    subtitle?: string;
    pageName: string;
    mdFile: string;
}

export const IntegrationsCard: React.FC<IIntegrationsCards> = ({
                                                                   icon = "",
                                                                   title = "",
                                                                   subtitle = "",
                                                                   pageName = "",
                                                                   mdFile
                                                               }) => {

    const [content, setContent] = useState("");

    useEffect(() => {
        // Load Teaser Text
        loadMD(mdFile).then((r) => {
            setContent(r)
        })
    }, [mdFile])

    const dispatch = useDispatch();

    return (
        <IonCard className="IntegrationsCard" mode="md" routerLink={`/page/${pageName}`} onClick={() => {

            dispatch(setPage({
                page: pageName
            }));

        }}>
            <IonCardHeader>
                <div>
                    <IonImg src={icon}/>
                </div>

                <IonCardTitle color="primary">{title}</IonCardTitle>
                {subtitle !== "" && <IonCardSubtitle color="secondary">{subtitle}</IonCardSubtitle>}
            </IonCardHeader>
            <IonCardContent>
                <ReactMarkdown plugins={[[gfm]]} children={content}/>
            </IonCardContent>
        </IonCard>
    );
};
