import React, {useMemo, useRef, useState} from 'react';
import {IonContent} from "@ionic/react";
import "./PageWebApps.scss";
import {WebAppsTeaser} from "../../components/content/WebAppsTeaser/WebAppsTeaser";
import {LazyLoader} from '../../components/LazyLoad';
import {Spacer} from '../../components/Spacer';
import {ContentBox} from "../../components/ContentBox";
import teaserWebapps from "../../cms/webapps/teaserWebapps.md"
import whyWebApps from "../../cms/webapps/whyWebApps.md"
import whyWebAppsQuote from "../../cms/webapps/quoteW2PWhy.md"
import webApp_smallToBig from "../../cms/webapps/webApps_smallToBig.md"
import webApp_smallToBigAdv from "../../cms/webapps/webApps_smallToBig_Adv.md"
import {Quote} from "../../components/content/Quote/Quote";
import {Grid} from "@material-ui/core";
import {ContentBlock} from "../../components/ContentBlock";
import {WebAppBurger} from "../../components/content/WebAppBurger/WebAppBurger";
import avatarQuote from "../../assets/avatars/adrian_stanek_02.jpg";
import {AppFooter} from "../../components/footer/AppFooter";
import WebAppComparisonTable from "../../components/content/webappComparison/WebAppComparisonTable";

export const PageWebApps: React.FC = () => {

    const containerRef = useRef(null);
    const [containerScroll, setContainerScroll] = useState(2);


    // Workaround to avoid flickering of table
    const comparison = useMemo(() => {
        return (<WebAppComparisonTable/>)
    }, [])


    return (

        <IonContent fullscreen ref={containerRef} id="scrollContainer" scrollEvents={true} onIonScroll={(e) => {
            setContainerScroll(e.detail.scrollTop);
        }}>

            <WebAppsTeaser/>

            <Spacer height={50}/>

            <LazyLoader container={containerRef} height={400} offset={0} containerScroll={containerScroll}>
                <ContentBlock>

                    <h1>Warum sind WebApps so interessant?</h1>

                    <label className={"columnTitle"}>Das beste aus beiden Welten</label>

                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <ContentBox mdFile={teaserWebapps} layout={"col1"} title={""}/>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <WebAppBurger/>
                        </Grid>
                    </Grid>


                </ContentBlock>
            </LazyLoader>

            <Spacer height={50}/>

            <LazyLoader container={containerRef} height={400} offset={0} containerScroll={containerScroll}>
                <ContentBlock>
                    {comparison}
                </ContentBlock>
            </LazyLoader>

            <Spacer height={50}/>

            <LazyLoader container={containerRef} height={400} offset={0} containerScroll={containerScroll}>
                <ContentBlock>
                    <h1>Warum sollten sie eine PWA nutzen?</h1>

                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <ContentBox mdFile={whyWebApps} layout={"col1"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Quote mdFile={whyWebAppsQuote} author={"Adrian Stanek, CTO"} avatar={avatarQuote}/>
                        </Grid>
                    </Grid>
                </ContentBlock>
            </LazyLoader>

            <LazyLoader container={containerRef} height={400} offset={0} containerScroll={containerScroll}>
                <ContentBlock>
                    <h1>Klein & schnell Starten, <span>groß & erfolgreich werden</span></h1>

                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <ContentBox mdFile={webApp_smallToBig} layout={"col1"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ContentBox mdFile={webApp_smallToBigAdv} layout={"col1"} cssClasses={`listStyle`}/>

                        </Grid>
                    </Grid>
                </ContentBlock>
            </LazyLoader>

            <Spacer height={50}/>

            <AppFooter/>

        </IonContent>
    );
};

