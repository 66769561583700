import {
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import "./Menu.scss";

import why from "../assets/icons/menu/why.svg";
import w2p from "../assets/icons/menu/w2p.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/main";
import { getAppPage } from "../store/appStore";
import webapp from "../assets/icons/menu/webapp.svg";

interface AppPage {
  url: string;
  icon: string;
  title: string;
  reference: string;
}

const Menu: React.FC = () => {
  let pageName = useSelector((state: RootState) =>
    getAppPage(state.appReducer)
  );

  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reference, setReference] = useState("");

  useEffect(() => {
    setReference(pageName ?? "");
  }, [pageName]);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonMenuToggle autoHide={false}>
            <IonRouterLink href="/page/why-bitsinmotion">
              <IonItem
                className={
                  window.location.pathname === "/page/why-bitsinmotion"
                    ? "selected"
                    : ""
                }
              >
                <IonImg src={why} />
                <IonLabel>Warum bitsinmotion</IonLabel>
              </IonItem>
            </IonRouterLink>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonRouterLink href="/page/webapps">
              <IonItem
                className={
                  window.location.pathname === "/page/webapps" ? "selected" : ""
                }
              >
                <IonImg src={webapp} />
                <IonLabel>Progressive-Web-Apps</IonLabel>
              </IonItem>
            </IonRouterLink>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonRouterLink href="/page/webtoprint">
              <IonItem
                className={
                  window.location.pathname === "/page/webtoprint"
                    ? "selected"
                    : ""
                }
              >
                <IonImg src={w2p} />
                <IonLabel>Web-to-Print</IonLabel>
              </IonItem>
            </IonRouterLink>
          </IonMenuToggle>
        </IonList>

        {/*<IonList id="labels-list">*/}
        {/*    <IonListHeader>Labels</IonListHeader>*/}
        {/*    {labels.map((label, index) => (*/}
        {/*        <IonItem lines="none" key={index}>*/}
        {/*            <IonIcon slot="start" icon={bookmarkOutline}/>*/}
        {/*            <IonLabel>{label}</IonLabel>*/}
        {/*        </IonItem>*/}
        {/*    ))}*/}
        {/*</IonList>*/}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
